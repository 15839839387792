<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">体系树</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">建立体系树是企业体系建设的一个基本环节，如下表：</p>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">体系树</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/27.png"
          class="SonImgW"
        />
      </div>
      <p class="SmallP">
        有人称体系树为“体系BOM”。很多企业的体系树是将按照国际标准（如IATF16949）的“元素树”去构建的，这几乎是认证的组织建设管理体系时的一个标准动作，换句话说，体系树是“元素树”的应用，如：
      </p>
      <div class="ImgP">
        <p class="SmallP" style="width: auto">IATF16949元素树</p>
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/28.png"
          class="SonImgW"
        />
      </div>
      <img
        @click="openWindow"
        src="@/assets/FirstPage-ProductSon/68.png"
        class="SonImgW"
      />
      <br />
      <br />
      <p class="SmallP" style="text-indent: 2em; padding: 5px 0">
        体系树就是对应要素树得出的。以这种方式建立的体系，元素树一定会映射到体系结构的文件层级上，下层文件是上层文件的展开，并通过引用关系将他们连接起来，就形成了一串葡萄——体系结构。加上第一层文件“管理手册”，就是组织所熟悉的“体系金字塔”，见下图。
      </p>
      <div class="ImgP">
        <img
          @click="openWindow"
          src="@/assets/FirstPage-ProductSon/3.png"
          class="SonImg"
          style="width: 250px"
        />
        <p class="SmallP" style="width: auto">体系文件的金字塔图</p>
      </div>
      <p class="SmallP" style="padding: 5px 0">
        要使体系具有特色就需要组织结合自身的特点和条件去应用元素树，并要拿捏得恰到好处。当年很多咨询师就靠这一手出入江湖的。
      </p>
      <p class="SmallP" style="padding: 5px 0">
        体系树是体系维护最基本的文件，在文件更改时往往会牵一发而动全身，体系树就会起到很大的作用。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openWindow() {
      window.open("https://www.yuntixi.cn", "_blank");
    },
  },
};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>